import React, { useState, useEffect, PropTypes } from "react"
import { Link } from "@StarberryUtils";
import { graphql } from 'gatsby'
import SEO from "../components/seo"

import Header from "../components/Header/Header";
import Breadcrumb from "../components/Home/Breadcrumb/Breadcrumb";
import Footer from "../components/Footer/Footer";
import StickyFooter from "../components/Footer/StickyFooter";
import NewsLetter from "../components/Home/NewsLetter/NewsLetter";
import Modules from '../components/modules'


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/bootstrap.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/grunticon.scss";
import "../scss/global.scss";
import "../scss/custom.scss";
import "./scss/default-template.scss";

const DefaultTemplate = (props) => {
  const [renderComponent, setRenderComponent] = useState(true);
  const [state, setState] = React.useState({
    showMenu: false
  })

  const handlerClick = () => {
    setState({ ...state, showMenu: !state.showMenu })
  }

  const GQLPage = props.data.glstrapi?.article;
  const Page_Modules = props.data.glstrapi?.article?.Modules;
  const Site = props.data.glstrapi?.siteConfig;

  let custom_css = ''
  let css_pagename = "";
  if (GQLPage.Custom_CSS_Classname) {
    custom_css = GQLPage.Custom_CSS_Classname
  }
  const wrapClass = GQLPage?.Pagename ? `default-template page-${GQLPage?.Pagename.toLowerCase().replace(/\s+/g, '-')}-wrap` : '';
  const layoutClass = GQLPage?.Layout ? `page-layout-${GQLPage?.Layout.toLowerCase().replace(/\s+/g, '-')}` : '';
  
  useEffect(() => {
    const handleInteraction = () => {
      // Call scriptInsert only once when needed
      if (!renderComponent) {
        scriptInsert();
        setRenderComponent(true);
      }
    };
  
    // Add event listeners
    window.addEventListener("mousemove", handleInteraction);
    window.addEventListener("keypress", handleInteraction);
    window.addEventListener("touchmove", handleInteraction);
  
    // Check for page condition once and set renderComponent accordingly
    if (GQLPage.Pagename !== "Home") {
      setRenderComponent(true);
    }
  
    // Cleanup to remove event listeners when component unmounts
    return () => {
      window.removeEventListener("mousemove", handleInteraction);
      window.removeEventListener("keypress", handleInteraction);
      window.removeEventListener("touchmove", handleInteraction);
    };
  }, [renderComponent, GQLPage.Pagename]); // Correct dependency array
  

  const scriptInsert = () => {
    if (document.getElementById("chat") === null) {
      var script = document.createElement('script');
      script.id = 'chat';
      script.src = '//code.jivosite.com/widget/fOl4Wvtle2';
      document.getElementsByTagName('head')[0].appendChild(script);      
    }
    // Insert BugHerd script if not already present
    if (!document.getElementById("bugherd")) {
      const bugherdScript = document.createElement("script");
      bugherdScript.id = "bugherd";
      bugherdScript.type = "text/javascript";
      bugherdScript.src = "https://www.bugherd.com/sidebarv2.js?apikey=7lp2rk2qzcaxrqwxgqe7gw";
      document.body.appendChild(bugherdScript);
    }
  }
  var metaTitle = GQLPage.Meta_Title;
  var metaDesc = GQLPage.Meta_Description;
  if (props.location.pathname.includes("property-news") && props.params['*'] == "living-in-dubai") {
    metaTitle = 'News and insights about living in Dubai';
    metaDesc = 'For the most up to date news and insightful analysis about living in Dubai, from our team of real estate professionals.';
  } else if (props.location.pathname.includes("property-news")) {
    var cat = props.params['*']
    if (cat) {
      cat = cat.replace('-', ' ');
    }
    metaTitle = `Property ${cat} news Dubai`;
    metaDesc = `Get all the latest real estate ${cat} news in Dubai right here at Dacha Real Estate. We cover everything from new property developments to market trends and more!`;
  }

  return (
    <div className={`${layoutClass} ${wrapClass} ${custom_css}`}>
      <SEO title={metaTitle} description={metaDesc} location={props.location} />
      <div className={`${state.showMenu ? "open-search-block" : ""} ${GQLPage.Banner_Image && (GQLPage.Layout === 'Default' || GQLPage.Layout === 'Our_Story_Landing') ? 'homepage' : (GQLPage.Layout === 'Static' || GQLPage.Layout === 'News_Landing') ? 'static-page' : ''}`}>
        <Header homeArticleId={props.pageContext} location={props.location} showMenu={state.showMenu} handlerClick={handlerClick} fixed={true}  TransparentHeader={GQLPage?.TransparentHeader} />       
       
        <Modules site={Site} page={GQLPage} modules={Page_Modules} {...props} />
        {renderComponent &&
          <>
            <Footer popularSearch={GQLPage.Popular_Search} />
            {(GQLPage._id === "60c09aa4abdf081f435b6450") &&
              <StickyFooter />
            }

          </>
        }
      </div>
    </div>
  )
}

export default DefaultTemplate

export const pageQuery = graphql`
  query defaultQuery($id: ID!) {
      glstrapi {
        article(id: $id, publicationState: LIVE) {
          _id
          Pagename
          Video_URL
          Mobile_Video
          Show_Search_Type
          Show_Google_Rating
          Meta_Title
          Meta_Description
          Layout
          TransparentHeader
          Header_Title
          Header_Content
          Header_Contact_Info
          Header_CTA_2_Label
          Header_CTA_1_Label
          Custom_CSS_Classname
          Useful_Information
          Header_Style
          imagetransforms
          Popular_Search
          Header_CTA_1_Link {
            id
          }
          Header_CTA_2_Link {
            id
          }
          Banner_Image {
            url
            alternativeText
          }
          Modules {
            ... on GLSTRAPI_ComponentModuleFeaturedProperties {
              Place
              Search_Type
              id
            }
            ... on GLSTRAPI_ComponentModuleAddBlock {
              id
              Add_Service {
                CTA_Label
                Content
                Image {
                  alternativeText
                  url
                  formats
                }
                CTA_Link {
                  id
                }
              }
            }
            ... on GLSTRAPI_ComponentModuleGoogleReviews {
              id
              Reviews_Background_Image {
                alternativeText
                url
              }
            }
            ... on GLSTRAPI_ComponentModuleStats {
              id
              Add_Stat {
                Content
                Value
              }
            }
            ... on GLSTRAPI_ComponentModuleGlobalModules {
              id
              Select
            }
            ... on GLSTRAPI_ComponentModuleServicesTiles {
              id
              Add_Service_Tile {
                Label
                Link {
                  id
                }
                Image {
                  alternativeText
                  url
                }
              }
              Service_Block_Title
            }
            ... on GLSTRAPI_ComponentModuleServiceCards {
              id
              SectionTitle
              Service_Cards {
                Description
                Image{
                  url
                }
                Title
                id
              }
            }
            ... on GLSTRAPI_ComponentModuleStaticContent {
              Add_Static_Content
            }
            ... on GLSTRAPI_ComponentModuleEmbedVideo {
              id
              Video_Embed_URL
              Video_Background_Image {
                alternativeText
                url
              }
            }
            ... on GLSTRAPI_ComponentModuleCollections {
              id
              Select_Collection
            }
            ... on GLSTRAPI_ComponentModuleForms {
              id
              Name
              Title
            }
            ... on GLSTRAPI_ComponentModuleTeamSlider {
              id
              Teams_Intro_Content
              Select_Peoples(sort: "Sort:asc",where: {Publish: true}) {
                Name
                Designation
                URL
                Tile_Image {
                  alternativeText
                  url
                }
                imagetransforms
                Video_URL
                Publish
              }
            }
            ... on GLSTRAPI_ComponentModuleFooterBlock {
              id
              type: __typename
              FooterBlockTitle: Title
              Content
              CTA_1_Label
              CTA_1_Action
              CTA_1_Link {
                id
                Link_Type
              }
              CTA_2_Label
              CTA_2_Action
              CTA_2_Link {
                id
                Link_Type
              }                
              Image {
                  alternativeText
                  url
                  formats
              }
            }
            ... on GLSTRAPI_ComponentComponentsSocialMedia {
              id
              Social_Media_Cards {
                Content
                Icon
                URL
                Value
                id
              }
            }
            ... on GLSTRAPI_ComponentModuleAnimationContentBlock {
              id
              Animation
              Content
              Title
            }
            ... on GLSTRAPI_ComponentModuleMap {
              id
              Latitude
              Longtitude
            }
            ... on GLSTRAPI_ComponentModuleMilestones {
              id
              type: __typename
              Milestone_details {
                Title
                Description
              }
            }
          }
          Show_Sidebar
          SidebarWidget {
            id
            UseDefault
            ShowAgents
            SidebarTitle
            SidebarContent
            OpeningHours
          }
          Sidebar_Peoples(where: {Publish: true}) {
            URL
            Designation
            Email
            Name
            Phone
            URL
            Tile_Image {
              url
            }
          }
          all_menus {
            Label
            Main_Parent {
              Label
              id
              URL
            }
            Sub_Parent {
              Label
              URL
              id
            }
          }
        }

        siteConfig {
          Address
          Email
          Phone
          Available_Timings
        }

      }
  }
`